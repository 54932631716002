<template>
  <VueMarkdown class="markdown" :source="source" v-bind="attrs" />
</template>

<script>
import VueMarkdown from 'vue-markdown'
import bindAll from '@platform-shared/mixins/bindAll'
export default {
  components: { VueMarkdown },
  mixins: [bindAll],
  props: {
    source: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="postcss">
.markdown {
  & > * + *,
  & li + li,
  & li > p + p {
    @apply mt-6;
  }
  & strong {
    @apply text-black font-bold;
  }
  & p:not(:first-child) {
    @apply mt-16;
  }
  & a {
    @apply text-black text-blue-500 underline;
  }
  & h2 {
    @apply leading-tight text-xl font-bold text-black mb-2 mt-10;
  }
  & h3 {
    @apply leading-tight text-lg font-bold text-black mt-8 -mb-2;
  }
  & blockquote {
    @apply border-l-4 border-grey-500 pl-4 italic;
  }
  & ul,
  & ol {
    @apply pl-20;
  }
  & ul {
    @apply list-disc;
  }
  & ol {
    @apply list-decimal;
  }
}
</style>
